export const ADMIN_CATEGORY_LIST_REQUEST =
  'admin/category/ADMIN_CATEGORY_LIST_REQUEST';
export const ADMIN_CATEGORY_LIST_SUCCESS =
  'admin/category/ADMIN_CATEGORY_LIST_SUCCESS';
export const ADMIN_CATEGORY_LIST_FAIL =
  'admin/category/ADMIN_CATEGORY_LIST_FAIL';

export const ADMIN_COUPON_LIST_REQUEST =
  'admin/coupon/ADMIN_COUPON_LIST_REQUEST';
export const ADMIN_COUPON_LIST_SUCCESS =
  'admin/coupon/ADMIN_COUPON_LIST_SUCCESS';
export const ADMIN_COUPON_LIST_FAIL = 'admin/coupon/ADMIN_COUPON_LIST_FAIL';

export const ADMIN_MEASUREMENT_QUESTION_LIST_REQUEST =
  'admin/mill/ADMIN_MEASUREMENT_QUESTION_LIST_REQUEST';
export const ADMIN_MEASUREMENT_QUESTION_LIST_SUCCESS =
  'admin/mill/ADMIN_MEASUREMENT_QUESTION_LIST_SUCCESS';
export const ADMIN_MEASUREMENT_QUESTION_LIST_FAIL =
  'admin/mill/ADMIN_MEASUREMENT_QUESTION_LIST_FAIL';

export const ADMIN_MEASUREMENT_GROUP_LIST_REQUEST =
  'admin/mill/ADMIN_MEASUREMENT_GROUP_LIST_REQUEST';
export const ADMIN_MEASUREMENT_GROUP_LIST_SUCCESS =
  'admin/mill/ADMIN_MEASUREMENT_GROUP_LIST_SUCCESS';
export const ADMIN_MEASUREMENT_GROUP_LIST_FAIL =
  'admin/mill/ADMIN_MEASUREMENT_GROUP_LIST_FAIL';

export const ADMIN_MILL_LIST_REQUEST = 'admin/mill/ADMIN_MILL_LIST_REQUEST';
export const ADMIN_MILL_LIST_SUCCESS = 'admin/mill/ADMIN_MILL_LIST_SUCCESS';
export const ADMIN_MILL_LIST_FAIL = 'admin/mill/ADMIN_MILL_LIST_FAIL';

export const ADMIN_HERO_CAROUSEL_ITEM_LIST_REQUEST =
  'admin/mill/ADMIN_HERO_CAROUSEL_ITEM_LIST_REQUEST';
export const ADMIN_HERO_CAROUSEL_ITEM_LIST_SUCCESS =
  'admin/mill/ADMIN_HERO_CAROUSEL_ITEM_LIST_SUCCESS';
export const ADMIN_HERO_CAROUSEL_ITEM_LIST_FAIL =
  'admin/mill/ADMIN_HERO_CAROUSEL_ITEM_LIST_FAIL';

export const ADMIN_FABRIC_LIST_REQUEST =
  'admin/fabric/ADMIN_FABRIC_LIST_REQUEST';
export const ADMIN_FABRIC_LIST_SUCCESS =
  'admin/fabric/ADMIN_FABRIC_LIST_SUCCESS';
export const ADMIN_FABRIC_LIST_FAIL = 'admin/fabric/ADMIN_FABRIC_LIST_FAIL';

export const ADMIN_GENERIC_PRODUCT_LIST_REQUEST =
  'admin/category/ADMIN_GENERIC_PRODUCT_LIST_REQUEST';
export const ADMIN_GENERIC_PRODUCT_LIST_SUCCESS =
  'admin/category/ADMIN_GENERIC_PRODUCT_LIST_SUCCESS';
export const ADMIN_GENERIC_PRODUCT_LIST_FAIL =
  'admin/category/ADMIN_GENERIC_PRODUCT_LIST_FAIL';

export const ADMIN_PRODUCT_LIST_REQUEST =
  'admin/product/ADMIN_PRODUCT_LIST_REQUEST';
export const ADMIN_PRODUCT_LIST_SUCCESS =
  'admin/product/ADMIN_PRODUCT_LIST_SUCCESS';
export const ADMIN_PRODUCT_LIST_FAIL = 'admin/product/ADMIN_PRODUCT_LIST_FAIL';

export const ADMIN_TAILOR_LIST_REQUEST =
  'admin/tailor-shop/ADMIN_TAILOR_LIST_REQUEST';
export const ADMIN_TAILOR_LIST_SUCCESS =
  'admin/tailor-shop/ADMIN_TAILOR_LIST_SUCCESS';
export const ADMIN_TAILOR_LIST_FAIL =
  'admin/tailor-shop/ADMIN_TAILOR_LIST_FAIL';

export const ADMIN_TAILOR_SHOP_LIST_REQUEST =
  'admin/tailor-shop/ADMIN_TAILOR_SHOP_LIST_REQUEST';
export const ADMIN_TAILOR_SHOP_LIST_SUCCESS =
  'admin/tailor-shop/ADMIN_TAILOR_SHOP_LIST_SUCCESS';
export const ADMIN_TAILOR_SHOP_LIST_FAIL =
  'admin/tailor-shop/ADMIN_TAILOR_SHOP_LIST_FAIL';
