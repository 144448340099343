import React from 'react';
import styled from 'styled-components';

import StyledCoverImage from '../../../../ui-library/styled/StyledCoverImage';
import { isFabricCartItem, isGarmentCartItem } from '../../store.utils';

const getSize = (size) => {
  switch (size) {
    case 'sm':
      return '46px';
    default:
      return '75px';
  }
};
const getMobileSize = (size) => {
  switch (size) {
    case 'sm':
      return '28px';
    default:
      return '55px';
  }
};
const StyledWrapper = styled.div`
  width: ${(props) => getSize(props.size)};
  max-width: ${(props) => getSize(props.size)};
  height: ${(props) => getSize(props.size)};
  max-height: ${(props) => getSize(props.size)};

  @media (max-width: 912px) {
    width: ${(props) => getMobileSize(props.size)};
    max-width: ${(props) => getMobileSize(props.size)};
    height: ${(props) => getMobileSize(props.size)};
    max-height: ${(props) => getMobileSize(props.size)};
  }
`;

const getImageURLForGarment = (item) => {
  let imageURLs;
  if (item.productImageURLs) imageURLs = item.productImageURLs;
  if (item.product && item.product.imageURLs)
    imageURLs = item.product.imageURLs;
  if (!imageURLs || imageURLs.length === 0) {
    return null;
  }
  return imageURLs[0];
};

const getImageURLForGenericItem = (item) => {
  let imageURLs;
  if (item.productImageURLs) imageURLs = item.productImageURLs;
  if (item.product && item.product.imageURLs)
    imageURLs = item.product.imageURLs;
  if (!imageURLs || imageURLs.length === 0) {
    return null;
  }
  return imageURLs[0];
};

const getImageURLForFabric = (item) => item?.product?.imageURL;

const getImageURL = (item) => {
  if (isFabricCartItem(item)) {
    return getImageURLForFabric(item);
  }
  if (isGarmentCartItem(item)) {
    return getImageURLForGarment(item);
  }
  return getImageURLForGenericItem(item);
};

const ProductImage = ({ item, size }) => {
  let url = getImageURL(item);

  return (
    <StyledWrapper size={size}>
      <StyledCoverImage src={url} size={size} />
    </StyledWrapper>
  );
};

export default ProductImage;
