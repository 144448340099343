import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Albatross, Box } from '../../../../ui-library/layout';
import CartEditControls from './CartEditControls';
import Price from './Price';
import ProductDetails from './ProductDetails';
import ProductImage from './ProductImage';
import Quantity from './Quantity';
import StockWarning from '../../product-details-accessories/StockWarning';

const StyledWrapper = styled(Box)`
  @media (max-width: 441px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
`;

const GenericCartListItem = ({ index, item, isEditable, onEdit, onRemove }) => {
  // Details of Row / Column setup
  // https://www.figma.com/file/JHyF0DVRXSIa5k819vk3ne/Concept-v1.0-(Copy)?node-id=412%3A2

  const alignItemsPos = isEditable ? 'flex-end' : 'center';
  const upperLimit = item.product.stockQuantity;

  return (
    <Row noGutters className='p-2'>
      <Col xs={2}>
        <ProductImage item={item} />
      </Col>
      <Col xs={10}>
        <Row style={{ margin: 'auto', paddingLeft: '12px' }}>
          <Col xs={1} className='p-0'></Col>
          <Col xs={7} className='p-0'>
            <ProductDetails item={item} />
          </Col>
          <Col xs={2} className='p-0'></Col>
          <Col xs={2} className='p-0'>
            {isEditable && (
              <CartEditControls
                index={index}
                onEdit={onEdit}
                onRemove={onRemove}
                item={item}
              />
            )}
          </Col>
        </Row>
        <Row style={{ margin: '18px auto 0 auto', paddingLeft: '12px' }}>
          <Col xs={1} className='p-0'></Col>
          <Col xs={11} className='p-0'>
            <StyledWrapper
              justifyContent='space-between'
              alignItems={alignItemsPos}
            >
              <Albatross>
                <Quantity
                  index={index}
                  item={item}
                  isEditable={isEditable}
                  upperLimit={upperLimit}
                />
              </Albatross>
              <Box flexDirection='column' alignItems='flex-end'>
                <Price item={item} />
              </Box>
            </StyledWrapper>
            <StockWarning product={item?.product} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GenericCartListItem;
