import { hasItemInList } from '../../utils/array';
import { CATEGORY_FABRICS, garmentCategories } from '../../utils/constants';

export const isFabric = (product) => product?.type === CATEGORY_FABRICS;
export const isFabricLineItem = (item) =>
  item.primaryCategoryName === CATEGORY_FABRICS;
export const isFabricCartItem = (item) =>
  item?.product?.type === CATEGORY_FABRICS;

export const isGarment = (product) =>
  hasItemInList(product?.primaryCategoryName, garmentCategories);

export const isGarmentLineItem = (item) =>
  hasItemInList(item?.primaryCategoryName, garmentCategories);

export const isGarmentCartItem = (item) =>
  hasItemInList(item?.product?.primaryCategoryName, garmentCategories);
