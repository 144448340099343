import React from 'react';

import { Banana, Earth, Orange } from '../../../../ui-library/typography';
import { Space } from '../../../../ui-library/space';
import { Albatross } from '../../../../ui-library/layout';
import { isFabricCartItem, isGarmentCartItem } from '../../store.utils';

const GarmentDetails = ({ item }) => {
  const { product, fabric } = item;
  return (
    <div>
      <Banana>{product.name}</Banana>
      <Space size={2} />
      <Albatross>
        <Earth lineHeight='15px'>Fabric : </Earth>
        <Orange lineHeight='18px'>{fabric.userChoice.name}</Orange>
      </Albatross>
    </div>
  );
};

const GenericDetails = ({ item }) => {
  const { product } = item;
  return (
    <div>
      <Banana>{product.name}</Banana>
    </div>
  );
};

const FabricDetails = ({ item }) => {
  const { product } = item;
  return (
    <div>
      <Banana>{product.name}</Banana>
    </div>
  );
};

const ProductDetails = (props) => {
  if (isFabricCartItem(props?.item)) return <FabricDetails {...props} />;
  if (isGarmentCartItem(props?.item)) return <GarmentDetails {...props} />;
  return <GenericDetails {...props} />;
};

export default ProductDetails;
