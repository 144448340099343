import React from 'react';
import styled from 'styled-components';

import { Box } from '../../../ui-library/layout';
import { Mercury } from '../../../ui-library/typography';
import { STOCK_WARNING_THRESHOLD } from '../../../utils/constants';
import theme from '../../../ui-library/theme';

const StyledWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  color: ${theme.colors.alert.main};
`;

const StockWarning = ({ product }) => {
  // Return null if there's enough stock
  if (product?.stockQuantity > STOCK_WARNING_THRESHOLD) return null;

  const warningMessage =
    product.stockQuantity > 0
      ? `Only ${product.stockQuantity} left in stock`
      : 'Out of stock';

  return (
    <StyledWrapper>
      <Box>
        <Mercury>{warningMessage}</Mercury>
      </Box>
    </StyledWrapper>
  );
};

export default StockWarning;
