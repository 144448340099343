import React from 'react';
import { Button } from '../../../../ui-library/buttons';
import {
  EditCartItemIcon,
  RemoveCartItemIcon,
} from '../../../../ui-library/icons';
import { Box } from '../../../../ui-library/layout';
import { isGarmentCartItem } from '../../store.utils';
import {
  trackEditProductInCart,
  trackRemoveProductFromCart,
} from '../../../tracking/shop.tracking';

const CartEditControls = ({ index, onEdit, onRemove, item }) => {
  const showEditButton = isGarmentCartItem(item);
  const handleEditItem = () => {
    onEdit(index);
    trackEditProductInCart();
  };
  const handleRemoveItem = () => {
    onRemove(index);
    trackRemoveProductFromCart();
  };
  return (
    <Box justifyContent='flex-end'>
      {showEditButton && (
        <Button
          icon={<EditCartItemIcon />}
          size='xs'
          onClick={handleEditItem}
          margin='0 12px'
        />
      )}
      <Button
        icon={<RemoveCartItemIcon />}
        size='xs'
        onClick={handleRemoveItem}
      />
    </Box>
  );
};

export default CartEditControls;
