import { useSelector } from 'react-redux';
import {
  URL_FOR_CHECKOUT_FIRST_STEP,
  URL_FOR_CHECKOUT_PAYMENT_STEP,
} from '../checkout/checkout.constants';

const useCart = () => {
  const cart = useSelector((state) => state.cart);

  const countOfItems = cart ? cart.length : 0;

  const getItemAtIndex = (index) => {
    if (index > -1 && index < cart.length) {
      return cart[index];
    }
    return null;
  };

  const hasItems = cart && cart.length > 0;

  const hasAllDigitalItems = () => {
    const digitalItems = cart?.filter((item) => item?.product?.isDigital);
    return digitalItems.length === cart.length;
  };

  // If cart has only digital items, go straight to payment (we don't need the address)
  // If cart has even one physical item, collect the address
  const checkoutRedirectURL = hasAllDigitalItems()
    ? URL_FOR_CHECKOUT_PAYMENT_STEP
    : URL_FOR_CHECKOUT_FIRST_STEP;

  return {
    cart,
    countOfItems,
    getItemAtIndex,
    hasItems,
    hasAllDigitalItems,
    checkoutRedirectURL,
  };
};

export default useCart;
