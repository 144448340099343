import React from 'react';
import styled from 'styled-components';

import { RoundedBorder } from '../../../../ui-library/layout';
import theme from '../../../../ui-library/theme';
import FabricCartListItem from './FabricCartListItem';
import GarmentCartListItem from './GarmentCartListItem';
import { isFabric, isGarment } from '../../store.utils';
import GenericCartListItem from './GenericCartListItem';

const StyledTopSeparator = styled.div`
  border-top: ${(props) => `1px solid ${props.theme.colors.grey.main}`}};
  margin: 16px 0;
  padding-top: 12px;
`;

/**
 * HOC that returns CartListItem component by wrapping it in a box (for primary variant)
 * or with a line separator between items (for secondary variant)
 * @param {Component} Component business component of CartListItem
 * @returns {Component} CartListItem with relevant boundary rendering
 */
const wrapCartListItem = (Component) => (props) => {
  const { variant, index } = props;
  const bDefaultStyle = !variant || variant === 'primary';
  const bSecondaryStyle = variant === 'secondary';
  const bNotFirstRow = index > 0;

  // Default style is with the rounded border. So, even if variant is not specified, we will default to this
  if (bDefaultStyle) {
    return (
      <RoundedBorder fill={theme.colors.white} variant='dark' className='mb-3'>
        <Component {...props} />
      </RoundedBorder>
    );
  }
  // In 'secondary' style, for all items other than the first, we want a top separator
  if (bSecondaryStyle && bNotFirstRow) {
    return (
      <StyledTopSeparator>
        <Component {...props} />
      </StyledTopSeparator>
    );
  }
  // We will come here if variant is secondary and it is the first item. In that case, just return the raw component
  return <Component {...props} />;
};

const CartListItem = (props) => {
  if (isFabric(props?.item?.product)) {
    return <FabricCartListItem {...props} />;
  } else if (isGarment(props?.item?.product)) {
    return <GarmentCartListItem {...props} />;
  }
  return <GenericCartListItem {...props} />;
};

export default wrapCartListItem(CartListItem);
