import axios from 'axios';

import { getFabricById } from '../customize-product/fabric/fabric.utils';
import { PC_CLEAR_CUSTOMIZATION } from '../customize-product/productCustomization.constants';
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_FABRIC,
  CART_UPDATE_MEASUREMENTS,
  CART_UPDATE_UNIT_OF_MEASUREMENT,
  CART_UPDATE_QUANTITY,
  CART_CLEAR_ALL,
  CART_UPDATE_OPTIONS,
  CART_UPDATE_PHOTOS,
  CART_INCREMENT_QUANTITY,
  CART_DECREMENT_QUANTITY,
  CART_DECREMENT_METERS,
  CART_INCREMENT_METERS,
  CART_UPDATE_METERS,
} from './cart.constants';

export const addItemToCart = (item) => async (dispatch) => {
  dispatch({ type: CART_ADD_ITEM, payload: item });
  dispatch({ type: PC_CLEAR_CUSTOMIZATION });
};

export const addItemToServerCart = (item) => async () => {
  axios.post('/api/v1/cart', { item }).catch((err) => {
    return err.response ? err.response.data : err;
  });
};

export const removeItemFromCart = (index) => async (dispatch) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: { index } });
};

export const removeItemFromServerCart = (index) => async () => {
  axios.delete(`/api/v1/cart/${index}`).catch((err) => {
    return err.response ? err.response.data : err;
  });
};

export const updateQuantityForProductAtIndex =
  (quantity, index, upperLimit) => async (dispatch) => {
    dispatch({
      type: CART_UPDATE_QUANTITY,
      payload: { quantity, index, upperLimit },
    });
  };

export const incrementQuantityForProductAtIndex =
  (index, upperLimit) => async (dispatch) => {
    dispatch({ type: CART_INCREMENT_QUANTITY, payload: { index, upperLimit } });
  };

export const decrementQuantityForProductAtIndex =
  (index) => async (dispatch) => {
    dispatch({ type: CART_DECREMENT_QUANTITY, payload: { index } });
  };

export const updateMetersForProductAtIndex =
  (meters, index) => async (dispatch) => {
    dispatch({ type: CART_UPDATE_METERS, payload: { meters, index } });
  };

export const incrementMetersForProductAtIndex = (index) => async (dispatch) => {
  dispatch({ type: CART_INCREMENT_METERS, payload: { index } });
};

export const decrementMetersForProductAtIndex = (index) => async (dispatch) => {
  dispatch({
    type: CART_DECREMENT_METERS,
    payload: { index },
  });
};

export const updateQuantityForProductOnServerCartAtIndex =
  (quantity, index) => async () => {
    axios
      .post(`/api/v1/cart/${index}/setQuantity`, { quantity })
      .catch((err) => {
        return err.response ? err.response.data : err;
      });
  };

export const incrementQuantityForProductOnServerCartAtIndex =
  (index) => async () => {
    axios.post(`/api/v1/cart/${index}/incrementQuantity`).catch((err) => {
      return err.response ? err.response.data : err;
    });
  };

export const decrementQuantityForProductOnServerCartAtIndex =
  (index) => async () => {
    axios.post(`/api/v1/cart/${index}/decrementQuantity`).catch((err) => {
      return err.response ? err.response.data : err;
    });
  };

// meters
export const updateMetersForProductOnServerCartAtIndex =
  (meters, index) => async () => {
    axios.post(`/api/v1/cart/${index}/setMeters`, { meters }).catch((err) => {
      return err.response ? err.response.data : err;
    });
  };

export const incrementMetersForProductOnServerCartAtIndex =
  (index) => async () => {
    axios.post(`/api/v1/cart/${index}/incrementMeters`).catch((err) => {
      return err.response ? err.response.data : err;
    });
  };

export const decrementMetersForProductOnServerCartAtIndex =
  (index) => async () => {
    axios.post(`/api/v1/cart/${index}/decrementMeters`).catch((err) => {
      return err.response ? err.response.data : err;
    });
  };

// DEPRECATED
export const updateFabricByIdForProductAtIndex =
  (fabricId, index) => async (dispatch) => {
    try {
      const fabric = await getFabricById(fabricId);
      if (fabric) {
        dispatch({ type: CART_UPDATE_FABRIC, payload: { index, fabric } });
      }
    } catch (err) {
      console.log(err);
    }
  };

// DEPRECATED
export const updateMeasurementsForProductAtIndex =
  (measurements, index) => async (dispatch) => {
    dispatch({
      type: CART_UPDATE_MEASUREMENTS,
      payload: { measurements, index },
    });
  };

// DEPRECATED
export const updateUnitOfMeasurementForProductAtIndex =
  (unitOfMeasurement, index) => async (dispatch) => {
    dispatch({
      type: CART_UPDATE_UNIT_OF_MEASUREMENT,
      payload: { unitOfMeasurement, index },
    });
  };

// Update full cart - called when cart item is being edited
export const updateItemAtIndex =
  (productCustomization, index) => async (dispatch) => {
    const {
      fabric: { userChoice },
      options,
      measurements,
      unitOfMeasurement,
      photos,
    } = productCustomization;
    dispatch({
      type: CART_UPDATE_OPTIONS,
      payload: { options, index },
    });
    dispatch({
      type: CART_UPDATE_FABRIC,
      payload: { index, fabric: userChoice },
    });
    dispatch({
      type: CART_UPDATE_MEASUREMENTS,
      payload: { measurements, index },
    });
    dispatch({
      type: CART_UPDATE_UNIT_OF_MEASUREMENT,
      payload: { unitOfMeasurement, index },
    });
    dispatch({
      type: CART_UPDATE_PHOTOS,
      payload: { photos, index },
    });
  };

export const updateItemAtIndexOnServerCart =
  (productCustomization, index) => async () => {
    axios
      .patch(`/api/v1/cart/${index}`, { productCustomization })
      .catch((err) => {
        return err.response ? err.response.data : err;
      });
  };

// Clear full cart
export const clearCart = () => async (dispatch) => {
  dispatch({ type: CART_CLEAR_ALL });
};

export const clearServerCart = () => async () => {
  axios.get('/api/v1/cart/clear').catch((err) => {
    return err.response ? err.response.data : err;
  });
};
