import { combineReducers } from 'redux';
import categoryReducer from './category/category.reducers.js';
import couponReducer from './coupon/coupon.reducers.js';
import heroCarouselItemReducer from './hero-carousel-item/hero-carousel-item.reducers.js';
import measurementGroupReducer from './measurement-group/measurement-group.reducers.js';
import measurementQuestionReducer from './measurement-question/measurement-question.reducers.js';
import millReducer from './mill/mill.reducers.js';
import fabricReducer from './fabric/fabric.reducers.js';
import genericProductReducer from './generic-product/generic-product.reducers.js';
import productReducer from './product/product.reducers.js';
import tailorReducer from './tailor/tailor.reducers.js';
import tailorShopReducer from './tailor-shop/tailor-shop.reducers.js';

export default combineReducers({
  categories: categoryReducer,
  coupons: couponReducer,
  heroCarouselItems: heroCarouselItemReducer,
  measurementGroups: measurementGroupReducer,
  measurementQuestions: measurementQuestionReducer,
  mills: millReducer,
  fabrics: fabricReducer,
  products: productReducer,
  genericProducts: genericProductReducer,
  tailors: tailorReducer,
  tailorShops: tailorShopReducer,
});
