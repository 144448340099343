import { SHIPPING_PRICE } from './cart.constants';
import { getProductTotal } from './cart.utils';
import useCoupon from './coupon/useCoupon';

const getShippingPrice = (cart) => {
  let shippingPrice = 0;
  cart.forEach((item) => {
    // if item has shipping price and it is greater than the calculated shipping price so far, use that instead
    if (
      typeof item?.product?.shippingPrice !== undefined &&
      item?.product?.shippingPrice >= shippingPrice
    ) {
      shippingPrice = item.product.shippingPrice;
    } else {
      // default shipping price for bespoke garments
      shippingPrice = SHIPPING_PRICE;
    }
  });

  return shippingPrice;
};

/**
 * Returns cart total
 * cartTotal: amount of all items in the cart
 * grandTotal: amount of all items in the cart + shipping charges
 * grandTotalAfterDiscount: amount after applying the coupon code discount
 * @param {Object} cart
 */
const useCalculateCartTotal = (cart) => {
  let cartTotal = 0;
  cart.forEach((item) => {
    cartTotal += getProductTotal(item);
  });

  const shipping = getShippingPrice(cart);

  const grandTotal = cartTotal + shipping;

  const { discountAmount } = useCoupon({ grandTotal });
  const grandTotalAfterDiscount = grandTotal - discountAmount;

  const isFree = grandTotalAfterDiscount === 0;

  return {
    cartTotal,
    discountAmount,
    isFree,
    shipping,
    grandTotal,
    grandTotalAfterDiscount,
  };
};

export default useCalculateCartTotal;
