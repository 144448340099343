import useModalState from '../../../utils/useModalState';
import useNavigation from '../../../utils/useNavigation';
import { useIsUserLoggedIn } from '../../auth/auth.utils';
import LoginRegisterModal from '../../auth/LoginRegisterModal';
import useCart from '../cart/useCart';

const useModalCheckout = () => {
  const [Modal, isOpen, show, hide] = useModalState(LoginRegisterModal);
  const isUserLoggedIn = useIsUserLoggedIn();
  const { goToCheckout, goToPayment } = useNavigation();
  const { hasAllDigitalItems } = useCart();

  const showLoginRegisterModal = () => {
    show();
  };
  const handleCheckout = () => {
    if (isUserLoggedIn) {
      hasAllDigitalItems() ? goToPayment() : goToCheckout();
    } else {
      showLoginRegisterModal();
    }
  };

  return {
    Modal,
    isOpen,
    show,
    hide,
    handleCheckout,
  };
};

export default useModalCheckout;
