import produce from 'immer';
import { isEmpty } from 'validate.js';

import { PC_DEFAULT_PATTERN_PRICE } from '../customize-product/productCustomization.constants';
import {
  CART_ADD_ITEM,
  CART_CLEAR_ALL,
  CART_DECREMENT_QUANTITY,
  CART_INCREMENT_QUANTITY,
  CART_DECREMENT_METERS,
  CART_INCREMENT_METERS,
  CART_UPDATE_METERS,
  CART_REMOVE_ITEM,
  CART_UPDATE_FABRIC,
  CART_UPDATE_MEASUREMENTS,
  CART_UPDATE_OPTIONS,
  CART_UPDATE_PHOTOS,
  CART_UPDATE_QUANTITY,
  CART_UPDATE_UNIT_OF_MEASUREMENT,
} from './cart.constants';
import { FETCH_USER } from '../../auth/auth.constants';
import { isGarment } from '../store.utils';
import { hasKey } from '../../../utils/object';
import { replaceLocalCartWithServerCart } from './cart.utils';

/*
Each object in the cart has the structure
{
  product, // Full product model
  quantity,
  fabric: {
    default, // Fabric model
    userChoice,
  },
  measurements: [
    {
      _id,
      title,
      value,
      didBlur,
    }
  ],
  unitOfMeasurement,
  patternPrice
}
*/
const initialState = [];

const getSimplifiedPayload = (payload) => {
  const { product } = payload;
  if (!isGarment(product)) {
    return payload; // for fabrics and generic products, there is no need to simplify the payload. Return obj as is
  }
  const obj = {
    ...payload,
    product: {
      _id: product._id,
      name: product.name,
      sku: product.sku,
      price: product.price,
      primaryCategory_id: product.primaryCategory_id,
      imageURLs: product.imageURLs,
      primaryCategoryName: product.primaryCategoryName,
    },
  };
  return obj;
};

const handleAddItem = (draft, payload) => {
  if (isGarment(payload.product)) {
    // For garments, apply pattern price before adding to cart
    for (let i = 0; i < draft.length; i++) {
      // check if category already exists in cart. If yes, do not charge pattern again
      if (
        draft[i].product.primaryCategory_id ===
        payload.product.primaryCategory_id
      ) {
        payload.patternPrice = 0;
        break;
      }
    }
    const simplifiedPayload = getSimplifiedPayload(payload);
    draft.push(simplifiedPayload);
    return;
  }

  // For fabrics and generic products, add item as is
  draft.push(payload);
};

export const hasPatternPrice = (draft, index) => draft[index].patternPrice > 0;

export const deleteItemAtIndex = (draft, index) => draft.splice(index, 1);

export const getIndexOfAnotherItemOfSameCategory = (draft, index) => {
  for (let i = 0; i < draft.length; i++) {
    if (
      draft[i].product.primaryCategory_id ===
        draft[index].product.primaryCategory_id &&
      i !== index
    ) {
      return i;
    }
  }

  return -1;
};
const handleRemoveItem = (draft, index) => {
  // If item has patternPrice, check if another item of the same category exists in the cart and transfer the price to that item. Then delete this item
  if (hasPatternPrice(draft, index)) {
    const i = getIndexOfAnotherItemOfSameCategory(draft, index);
    if (i >= 0) {
      draft[i].patternPrice = PC_DEFAULT_PATTERN_PRICE;
    }
  }
  deleteItemAtIndex(draft, index);
};

/**
 * Returns true if given categoryId is found in user's previous purchases
 * ie, in profile.purchasedCategoryIds
 * @param {Object} profile Customer Profile
 * @param {String} categoryId Category Id
 * @returns Boolean
 */
export const hasPatternInProfileForProductCategory = (profile, categoryId) => {
  if (isEmpty(profile) || !hasKey(profile, 'purchasedCategoryIds'))
    return false;
  return profile.purchasedCategoryIds[categoryId];
};

export const getMeasurementsInProfileForProductCategory = (
  profile,
  categoryId
) => {
  // Return null if there are no measurements
  if (isEmpty(profile) || isEmpty(profile.measurements)) return null;

  // Return null if there are no measurements for given category
  const measurements = profile.measurements.filter(
    (ele) => ele.primaryCategory_id === categoryId
  );
  if (measurements.length === 0) return null;

  // Return the measurements. (There should only be one)
  return measurements[0].measurements;
};

/**
 * Updates patternPrice in cart after user signs in. This is needed for the following scenario:
 *
 * - User has previously purchased a product
 * - User is currently browsing website without being signed in
 * - User buys a product in the same category (pattern price will be applied)
 * - User signs in
 *
 * We now have to remove the previously applied patternPrice and load their measurements for this category
 *
 * @param {Object} draft immer draft
 * @param {Object} profile Customer Profile
 */
const updateCartForProfile = (draft, profile) => {
  if (isEmpty(draft) || isEmpty(profile)) return;

  draft.forEach((item) => {
    const categoryId = item?.product?.primaryCategory_id;

    // reset patternPrice to 0 if user has already purchased product in this category
    if (hasPatternInProfileForProductCategory(profile, categoryId)) {
      item.patternPrice = 0;
    }

    // load measurements for this category, if present
    const measurements = getMeasurementsInProfileForProductCategory(
      profile,
      categoryId
    );
    if (measurements) {
      item.measurements = measurements;
    }
  });
};

const syncCarts = (draft, user) => {
  // no items in local cart and server has items
  // then populate local cart with server cart items
  if (draft.length === 0 && user?.cart?.length > 0) {
    replaceLocalCartWithServerCart(draft, user.cart);
  }
  /*
   Currently, the following scenario is not supported - local cart has items but server does NOT have items. This is an unlikely scenario and is not being developed right now because of the complication in keeping auth.cart in sync. ie,
   - local cart has items
   - items must be synced with server
   - items must be synced with auth.cart. This part is challenging because of multiple state changes - in the same dispatch call, we need to fetch the user, update the local cart and then update the auth (user) again with the local cart. It's too circular and considering how much of an edge case this is, it is not currently being developed.
  */
  // if local cart already has items, then local cart takes precedence
  // else if (draft.length > 0) {
  //   replaceServerCartWithLocalCart(draft);
  // }
};

const cartReducer = produce((draft, action) => {
  const { payload } = action;
  const index = payload?.index;
  const upperLimit = payload?.upperLimit;

  const validIndex = (index) => index > -1 && index < draft.length;
  switch (action.type) {
    case CART_ADD_ITEM:
      handleAddItem(draft, payload);
      break;
    case CART_REMOVE_ITEM:
      if (validIndex(index)) {
        handleRemoveItem(draft, index);
      }
      break;
    case CART_UPDATE_QUANTITY:
      if (validIndex(index)) {
        let newVal = payload.quantity;
        if (newVal > upperLimit) newVal = upperLimit;
        if (newVal < 1) newVal = 1;
        draft[index].quantity = newVal;
      }
      break;
    case CART_INCREMENT_QUANTITY:
      if (validIndex(index)) {
        let newVal = parseInt(draft[index].quantity) + 1;
        if (newVal > upperLimit) newVal = upperLimit;
        draft[index].quantity = newVal;
      }
      break;
    case CART_DECREMENT_QUANTITY:
      if (validIndex(index)) {
        let newVal = parseInt(draft[index].quantity) - 1;
        if (newVal < 1) newVal = 1;
        draft[index].quantity = newVal;
      }
      break;
    case CART_UPDATE_METERS:
      if (validIndex(index)) {
        let newVal = payload.meters;
        if (newVal < 1) newVal = 1;
        draft[index].meters = newVal;
      }
      break;
    case CART_INCREMENT_METERS:
      if (validIndex(index)) {
        draft[index].meters = parseFloat(draft[index].meters) + 0.5;
      }
      break;
    case CART_DECREMENT_METERS:
      if (validIndex(index)) {
        let newVal = parseFloat(draft[index].meters) - 0.5;
        if (newVal < 1) newVal = 1;
        draft[index].meters = newVal;
      }
      break;
    case CART_UPDATE_FABRIC:
      if (validIndex(index)) {
        draft[index].fabric.userChoice = payload.fabric;
      }
      break;
    case CART_UPDATE_MEASUREMENTS:
      if (validIndex(index)) {
        draft[index].measurements = payload.measurements;
      }
      break;
    case CART_UPDATE_OPTIONS:
      if (validIndex(index)) {
        draft[index].options = payload.options;
      }
      break;
    case CART_UPDATE_UNIT_OF_MEASUREMENT:
      if (validIndex(index)) {
        draft[index].unitOfMeasurement = payload.unitOfMeasurement;
      }
      break;
    case CART_UPDATE_PHOTOS:
      if (validIndex(index)) {
        draft[index].photos = payload.photos;
      }
      break;
    case CART_CLEAR_ALL:
      draft.splice(0, draft.length);
      break;
    case FETCH_USER:
      updateCartForProfile(draft, action.payload.customerProfile_id);
      syncCarts(draft, payload);
      break;
  }
}, initialState);

export default cartReducer;
